"use client";

import { useEffect, useState } from "react";
import { Error404 } from "@/components/Errors/Error404";

export default function Custom404() {
  const [onClient, setOnClient] = useState(false);

  useEffect(() => {
    setOnClient(true);
    // Have to do this because of this issue: https://github.com/vercel/next.js/issues/45620
    document.title = "Your page could not be found";
  }, []);

  if (onClient) return <Error404 />;

  return null;
}
